import React, { Component } from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const validationSchema = Yup.object().shape( {
  name: Yup.string()
  .min( 2, 'Too Short!' )
  .max( 50, 'Too Long!' )
  .required( '* Name is Required.' ),
  course: Yup.string(),
  vendor: Yup.string(),
  company: Yup.string(),
  email: Yup.string()
  .email( '* Enter a Valid Email.' )
  .required( '* Email is Required.' ),
  message: Yup.string()
  .required( '* Message is Required.' ),
} );

const encode = ( data ) => {
  return Object.keys( data )
  .map( key => encodeURIComponent( key ) + '=' + encodeURIComponent( data[ key ] ) )
  .join( '&' )
};

class TrainingEnquiryForm extends Component {

  constructor( props ) {
    super( props );
    this.state = {
      success: false,
    }
  }

  render() {
    return (
      <>
        <h3>Enquire about this training course</h3>
        <Formik
          initialValues={{ course: this.props.course, vendor: this.props.vendor, name: '', email: '', company: '', message: '' }}
          validationSchema={validationSchema}
          onSubmit={( values, { setSubmitting } ) => {
            fetch( '/', {
              method: 'POST',
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
              body: encode( {
                'form-name': 'training-enquiry',
                ...values,
              } ),
            } )
            .then( () => {
              alert( 'Success!' );
              setSubmitting( false )
            } )
            .catch( error => {
              alert( 'Error: Please Try Again!' );
              setSubmitting( false )
            } )
          }}
          render={( {
                      isSubmitting,
                      handleSubmit,
                      handleReset,
                    } ) => (
            <form className='form'
              name='training-enquiry'
              onSubmit={handleSubmit}
              onReset={handleReset}
              data-netlify='true'
              data-netlify-honeypot='bot-field'
            >
              <input type="hidden" name="course" value={this.props.course} />
              <input type="hidden" name="vendor" value={this.props.vendor} />
              <div className='field'>
                <label htmlFor='name' className='label'>Name</label>
                <Field
                  className='input'
                  type='text'
                  name='name'
                />
                <ErrorMessage className="errorMsg" name="name" component="small"/>
              </div>
              <div className='field'>
                <label htmlFor='email' className='label'>Email</label>
                <Field
                  className='input'
                  type='text'
                  name='email'
                />
                <ErrorMessage className="errorMsg" name="email" component="small"/>
              </div>
              <div className='field'>
                <label htmlFor='name' className='label'>Company Name</label>
                <Field
                  className='input'
                  type='text'
                  name='company'
                />
                <ErrorMessage className="errorMsg" name="company" component="small"/>
              </div>
              <div className='field'>
                <label htmlFor='message' className='label'>Message</label>
                <Field
                  className='input-textarea'
                  name='message'
                  component='textarea'
                  rows='3'
                />
                <ErrorMessage className="errorMsg" name="message" component="small"/>
              </div>
              <Buttons>
                <input name='submit' type='submit' disabled={isSubmitting} value='Send Message'
                  className='button'/>
              </Buttons>
            </form>
          )}
        />
      </>
    )
  }
}

TrainingEnquiryForm.propTypes = {
  course: PropTypes.string.isRequired,
  vendor: PropTypes.string.isRequired,
};

export default TrainingEnquiryForm;
